<template>
    <div>
        <!-- <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          会员管理<i class="el-icon-arrow-right"></i>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
        <!-- 卡片 -->
        <!-- {{$store.state.config.user_type}} -->
        <el-card style="margin-bottom: 10px">
            <nav class="out">
                <nav class="input" style="width: 305px">
                    <el-input v-model="keyword" size="medium" placeholder="输入昵称，姓名，手机，公司，职位" />
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="status" placeholder="状态" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="禁用" :value="2" />
                        <el-option label="启用" :value="1" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="service" placeholder="小助手" size="medium">
                      <el-option label="全部" :value="-1" />
                      <el-option label="已绑定小助手" :value="-2" />
                      <el-option label="未绑定小助手" :value="-3" />
                        <el-option
                            v-for="(item, index) in $store.state.NormalList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="showCircles" placeholder="圈子展示" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="展示" :value="1" />
                        <el-option label="不展示" :value="0" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="certType" placeholder="认证类型" size="medium">
                        <el-option label="全部类型" :value="-2" />
                        <el-option label="未认证" :value="-1" />
                        <el-option label="已认证" :value="1" />
                        <!-- <el-option label="企业认证" :value="2"></el-option> -->
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="completeInfo" placeholder="完善状态" size="medium">
                        <el-option label="全部类型" value="0" />
                        <el-option label="未完善" value="1" />
                        <el-option label="已完善" value="2" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="bindEnterprise" placeholder="绑定企业" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option label="已绑定" :value="1" />
                        <el-option label="未绑定" :value="0" />
                    </el-select>
                </nav>
                <nav class="input" style="width: 150px">
                    <el-select v-model="jobLevel" placeholder="职级" size="medium">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in $store.state.config && $store.state.config.userJobLevel"
                            :key="index"
                            :label="item"
                            :value="index"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: auto">
                    <!-- {{findCity(provinceId).toString()}} -->
                    <el-select
                        v-model="provinceId"
                        placeholder="省份"
                        size="medium"
                        style="width:150px;margin-right: 5px;"
                    >
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in province && province"
                            :key="index"
                            :label="item.name"
                            :value="item.code"
                        />
                    </el-select>
                    <el-select v-model="cityId" placeholder="城市" size="medium" style="width:150px">
                        <el-option label="全部" :value="-1" />
                        <el-option
                            v-for="(item, index) in cityList"
                            :key="index"
                            :label="item.name"
                            :value="item.code"
                        />
                    </el-select>
                </nav>
                <nav class="input" style="width: auto;margin-right: 0px !important;">
                    <type-business-subBusiness  :form.sync="querifo"></type-business-subBusiness>
                </nav>
                <nav class="input" style="width: auto;">
                    <el-select
                        v-model="regType"
                        @change="
                            regDate = [];
                            certDate = [];
                        "
                        size="medium"
                        placeholder="请选择"
                        style="width:150px;margin-right: 5px;"
                    >
                        <el-option label="注册时间" value="1"></el-option>
                        <el-option label="认证时间" value="2"></el-option>
                    </el-select>
                    <div style="width:300px;display:inline-block;">
                        <el-date-picker
                            v-show="regType == 1"
                            v-model="regDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            size="medium"
                        />
                        <el-date-picker
                            v-show="regType == 2"
                            v-model="certDate"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                            size="medium"
                        />
                    </div>
                </nav>
<!--                <nav class="input" style="width: auto;">-->
<!--                    <load-button ref="loadButton" :click="synchronousInterior">同步内部会员信息</load-button>-->
<!--                </nav>-->

                <nav  class="input"  style="width: auto">
                  <nav class="input" style="width:auto">
                    <el-button size="small" @click="reset">重置</el-button>
                    <el-button type="primary" size="small" @click="subimt">{{ btn ? "搜索" : "loading.." }}</el-button>
                  </nav>
                  <el-button type="primary" size="small" @click="$refs.sendSms.init(cQuery)" plain>{{
                      "添加到短信收件人"
                    }}</el-button>
                    <el-button type="primary" v-show="$store.state.btn_if.indexOf('user_add') != -1" size="small" @click="add_user"  plain>{{ "添加会员" }}</el-button>
                  <el-button type="primary" v-if="isDerive"    size="small" @click="handleDerived" plain>导出</el-button>
                </nav>
            </nav>
        </el-card>
        <el-card class="box-card">
            <el-table
                :data="userlist"
                border
                stripe
                :default-sort="{ prop: 'id', order: 'descending' }"
                :header-cell-style="rowClass"
                @sort-change="sortChange"
            >
                <el-table-column label="ID" prop="id" sortable="custom" />
                <el-table-column label="头像" prop="avatar" width="120px">
                    <template slot-scope="{ row }">
                        <!-- <img
              :src="
                !!row.avatar
                  ? baseurl + yu + row.avatar
                  : baseurl + yu + '/static/pc/img/avatar.png'
              "
              alt=""
              style="width: 80px; height: 80px"
            
            > -->
                        <p-image
                            @click.native="showEdit(row.id)"
                            :src="!!row.avatar ? row.avatar : baseurl + yu + '/static/pc/img/avatar.png'"
                            :view="false"
                        ></p-image>
                        <div
                            v-show="row.businessCard && $store.state.btn_if.indexOf('user_show_business_card') != -1"
                            @click="certification(row.businessCard)"
                        >
                            <el-tag style="cursor: pointer;" size="small" type="warning">认证凭证</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="名字" prop="realName">
                    <template slot-scope="{ row }">
                        {{ row.realName || row.nickname }}
                    </template>
                </el-table-column>
                <el-table-column label="所在城市" prop="city">
                    <template slot-scope="{ row }">
                        {{ row.province }}<span v-if="row.province && row.city">·</span>{{ row.city }}
                    </template>
                </el-table-column>
                <el-table-column label="等级" prop="gradeName">
                    <template slot-scope="{ row }">
                        <p>{{ row.gradeName }}</p>
                        <p v-if="row.vipEndTime">{{ row.vipEndTime }}到期</p>
                        <el-tag type="primary">{{
                            row.certStatus == 2 ? "已认证" : row.completeInfo ? "已完善" : "未完善"
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="职位" prop="job" />
                <el-table-column label="职级" prop="jobLevelName" />

                <el-table-column label="公司" prop="company" width="110px">
                    <template slot-scope="{ row }">
                        <p>{{ row.company }}</p>
                        <p style="font-size: 12px; color:red" v-if="row.company && row.enterpriseId == 0">
                            (未录入企业库)
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="类型行业" prop="type">
                    <template slot-scope="{ row }">
                        {{ row.type.toString() + "/" + row.business.toString() }}
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="ew">
                        <!-- {{ scope.row }} -->
                        <el-tag v-if="ew.row.status == 1" type="warning" size="small">正常</el-tag>
                        <el-tag v-else type="info" size="small">禁用</el-tag>

                    </template>
                </el-table-column>
                <el-table-column label="注册时间" prop="createTime">
                    <template #header>
                        <p>注册时间</p>
                        <p>认证时间</p>
                    </template>
                    <template slot-scope="{ row }">
                        <p>{{ row.createTime }}</p>
                        <p>{{ row.certCreateTime }}</p>
                    </template>
                </el-table-column>
                <el-table-column sortable="custom" prop="orderNum">
                    <template #header>
                        <span>排序</span>
                        <br /><span style="font-size:12px">（当前最大值：{{ orderMax }}）</span>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content">数值越大，显示越靠前</div>
                            <i class="el-icon-warning-outline" style="margin-left: 5px;" />
                        </el-tooltip>
                    </template>
                    <template slot-scope="{ row }">
                        <order-num
                            :row="row"
                            :val="{
                                url: '/admin/User/editOrderNum',
                                pw: 'user_order',
                            }"
                            @getlist="getuserlist"
                        />
                    </template>
                </el-table-column>

                <el-table-column label="备注" prop="remark">
                    <template slot-scope="{ row }">
                        <Remark
                            :user="'1'"
                            :row="row"
                            :val="{ url: '/admin/User/editInfo', pw: 'user_list_remark',user:'是否将备注同步至内部用户' }"
                            :getlist="getuserlist"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="运营备注" prop="remark">
                    <template slot-scope="{ row }">
                        <Remarks

                            v-show="$store.state.btn_if.indexOf('user_list_operation_remark') != -1"
                            :row="row"
                            rowKey="operationRemark"
                            :permissions="true"
                            url="/admin/User/editOperationRemark"
                            @getlist="getlist"
                        />
                        <template v-if="$store.state.btn_if.indexOf('user_list_operation_remark') == -1">
                            {{ row.operationRemark }}</template
                        >
                    </template>
                </el-table-column>
                <el-table-column label="小助手" prop="waId" width="140px">
                    <template slot-scope="{ row }">
                        <el-select
                            v-model="row.waId"
                            placeholder="小助手"
                            size="medium"
                            multiple
                            :multiple-limit="3"
                            :disabled="$store.state.btn_if.indexOf('user_list_service') == -1"
                            @change="handleInputBlur(row)"
                            @visible-change="visibleChange"
                            @remove-tag="handleRemove"
                        >
                            <el-option
                                v-for="(item, index) in $store.state.NormalList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id + ''"
                            />
                        </el-select>
                      <Remark
                          style="margin-top: 20px"
                          :row="row"
                          :rowKey="'crowd'"
                          url="/admin/User/editCrowd"
                          @getlist="getlist"
                          :val="{ url: '/admin/User/editCrowd',pw:'user_list_service', user:'是否将备注同步内部用户' }"
                      ></Remark>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button
                            v-show="scope.row.certStatus == 2 && $store.state.btn_if.indexOf('user_cancel_cert') != -1"
                            type="text"
                            icon="el-icon-refresh-left"
                            size="mini"
                            @click="cancelCert(scope.row.id)"
                            >撤销认证</el-button
                        >

                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_top') != -1"
                            type="text"
                            icon="el-icon-share"
                            size="mini"
                            style="color:tan"
                            @click="editTop(scope.row, scope.row.top == 0 ? 1 : 0)"
                            >{{ scope.row.top == 0 ? "圈子推荐" : "取消圈子推荐" }}</el-button
                        >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_list_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            class="skyblue"
                            @click="showEdit(scope.row.id)"
                            >编辑</el-button
                        >

                        <el-button
                            v-if="scope.row.status == 2"
                            v-show="$store.state.btn_if.indexOf('user_list_status') != -1"
                            type="text"
                            icon="el-icon-setting"
                            size="mini"
                            class="yellow"
                            @click="setRole(scope.row.id, 1)"
                            >启用
                        </el-button>
                        <el-button
                            v-else
                            v-show="$store.state.btn_if.indexOf('user_list_status') != -1"
                            type="text"
                            icon="el-icon-setting"
                            size="mini"
                            class="yellow"
                            @click="setRole(scope.row.id, 2)"
                            >禁用</el-button
                        >
                      <el-button
                          v-if="scope.row.riskStatus == 1"
                          v-show="$store.state.btn_if.indexOf('user_risk_status') != -1"
                          type="text"
                          icon="el-icon-setting"
                          size="mini"
                          class="yellow"
                          @click="setRisk(scope.row.id, 2)"
                      >风险提示
                      </el-button>
                      <el-button
                          v-else
                          v-show="$store.state.btn_if.indexOf('user_risk_status') != -1"
                          type="text"
                          icon="el-icon-setting"
                          size="mini"
                          class="yellow"
                          @click="setRisk(scope.row.id, 1)"
                      >取消风险提示</el-button
                      >
                        <el-button
                            v-show="$store.state.btn_if.indexOf('user_get_contact') != -1"
                            type="text"
                            icon="el-icon-camera"
                            size="mini"
                            class="green"
                            @click="seeTheLink(scope.row.id)"
                            >查看联系方式</el-button
                        >
                        <el-button
                            v-if="scope.row.enterpriseId > 0"
                            type="text"
                            icon="el-icon-s-release"
                            size="mini"
                            @click="solutionEnterprise(scope.row)"
                            >解绑企业</el-button
                        >
                        <el-button
                            v-if="$store.state.btn_if.indexOf('user_list_edit_grade') != -1"
                            type="text"
                            icon="el-icon-camera"
                            size="mini"
                            @click="show_qy(scope.row)"
                            >修改等级</el-button
                        >
                      <el-button

                          v-if="$store.state.btn_if.indexOf('user_send_vip') != -1"
                          type="text"
                          icon="el-icon-camera"
                          size="mini"
                          class="green"
                          @click="sendVip(scope.row)"
                      >赠送Vip卡</el-button
                      >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="querifo.pagenum"
                :page-size="querifo.pagesize"
                layout="total, prev, pager, next"
                :total="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="950px" @close="addColse">
            <el-form ref="addFormRef" label-width="140px" :model="editform" :rules="addFormRulue">
                <el-tag
                    size="medium"
                    style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          "
                    >个人资料</el-tag
                >
                <el-form-item label="昵称" prop="nickname">
                    <el-input v-model="editform.nickname" />
                </el-form-item>
                <el-form-item label="姓名" prop="realName">
                    <el-input v-model="editform.realName" />
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <el-radio-group v-model="editform.sex">
                        <el-radio :label="0">未知</el-radio>
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="editform.email" />
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input v-model="editform.phone" />
                </el-form-item>
                <el-form-item label="QQ号码">
                    <el-input v-model="editform.qq" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="editform.remark" />
                </el-form-item>
                <el-form-item label="小助手">
                    <el-select v-model="editform.waId" placeholder="小助手" size="medium" multiple :multiple-limit="3">
                        <el-option
                            v-for="(item, index) in $store.state.NormalList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
              <el-form-item label="小助手备注" prop="crowd">
                <el-col :span="8">
                  <el-input style="width: 89%" v-model="editform.crowd" >

                  </el-input>
                </el-col>


              </el-form-item>

              <el-form-item label="微信号" prop="weixin">
                    <el-input v-model="editform.weixin" />
                </el-form-item>
                <!-- <el-form-item label="头像">
          <img :src="
              editform.avatar
                ? baseurl + yu + editform.avatar
                : baseurl + yu + '/static/pc/img/avatar.png'
            " class="avatar" />
        </el-form-item> -->
                <el-form-item label="背景图片">
                    <up-img :action="action" :banner="editform.backgroundImage" :tip_text="false" @event="setImg" />
                </el-form-item>
                <el-form-item label="业务介绍" prop="businessInfo">
                    <el-input v-model="editform.businessInfo" type="textarea" :rows="3" />
                </el-form-item>
                <el-form-item label="职位" prop="job">
                    <el-input v-model="editform.job" />
                </el-form-item>
                <el-form-item label="级别" prop="jobLevel">
                    <el-select
                        v-model.trim="editform.jobLevel"
                        placeholder="请选择"
                        style="width: 120px; margin-right: 12px"
                    >
                        <el-option
                            v-for="(item, index) in $store.state.config && $store.state.config.userJobLevel"
                            :label="item"
                            :value="index * 1"
                            :key="index"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="是否展示到圈子" prop="isShowCircles">
                    <el-radio-group v-model="editform.isShowCircles">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否展示联系方式" prop="contactType">
                    <table>
                        <tr>
                            <th>
                                <el-select v-model="editform.contactType">
                                    <el-option
                                        :label="item.title"
                                        :value="+item.contactType"
                                        v-for="item in $store.state.contactType"
                                        :key="item.contactType"
                                    >
                                    </el-option>
                                </el-select>
                            </th>

                            <th>
                                <div v-show="editform.contactType != 3" class="box">
                                    <el-checkbox-group v-model="editform.showContacts" :min="1">
                                        <el-checkbox label="1">手机号</el-checkbox>
                                        <el-checkbox label="2">微信</el-checkbox>
                                        <el-checkbox label="3">QQ</el-checkbox>
                                        <el-checkbox label="4">邮箱</el-checkbox>
                                        <el-checkbox label="5">其他联系方式</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </th>
                        </tr>
                        <tr v-show="editform.contactType == 1 && editform.showContacts.indexOf('5') != -1">
                            <td />
                            <td>
                                <div class="box">
                                    <el-input v-model="editform.otherContact" />
                                </div>
                            </td>
                        </tr>
                        <tr v-show="[1,2].includes(+editform.contactType)">
                            <td />
                            <td>
                                <div class="box">
                                    至少选中其中两项
                                </div>
                            </td>
                        </tr>
                    </table>
                </el-form-item>
                <!-- <el-form-item label="会员等级" prop="ugId">
          <el-select v-model.trim="editform.ugId" placeholder="请选择" style="width: 120px; margin-right: 12px">
            <el-option
              v-for="(item, index) in $store.state.config &&
                $store.state.config.userGrade.slice(0)"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item> -->
                <el-tag
                    size="medium"
                    style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          "
                    >企业信息</el-tag
                >
                <el-form-item label="企业名称" prop="enterpriseId">
<!--                   <el-select v-model="editform.enterpriseId" filterable remote reserve-keyword placeholder="请输入企业名称模糊查询" :remote-method="remoteMethod" :loading="loading" no-data-text="搜索不到当前输入的企业，请到企业管理中新增企业" @change="show_one">-->
<!--            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />-->
<!--          </el-select>-->
                    <div-autocomplete
                        :selectId.sync="editform.enterpriseId"
                        :value.sync="editform.company"></div-autocomplete>
                </el-form-item>

            </el-form>

            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>

        <Member ref="member" @getlist="getuserlist" />
        <pass
            ref="pass"
            :dialog-visible.sync="p_a"
            :row="p_a_f"
            :type="'user'"
            reqUrl="/admin/User/editGrade"
            @getlist="getuserlist"
        />
        <Slink ref="link" />
        <!-- 这是分配角色的对话框 -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="
                () => {
                    showViewer = false;
                }
            "
            :url-list="[showurl]"
        />
        <send-sms ref="sendSms" type="1"></send-sms>
      <el-dialog :visible.sync="vipDialog" title="赠送Vip卡" width="30%"
                 center>
        <el-select v-model="form_4.vcId" placeholder="请选择">
          <el-option
              v-for="item in vipOption"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
        <el-button @click="vipDialog=false">取 消</el-button>
        <el-button type="primary" @click="submitVip">{{
            btn ? '确 定' : 'loading..'
          }}</el-button>
      </span>
      </el-dialog>

    </div>
</template>

<script>
// import $ from 'jquery'
import remark from "../components/remark/index.vue";
import Remarks from "@/components/global/Remark/index.vue";
import Remark from "@/components/remark/index.vue";
import orderNum from "../components/remark/orderNum.vue";
import upImg from "../components/upImg/";
import pass from "./enterprise/enterprise_cert_list/pass.vue";
import websocket from "@/mixin/websocket.js";
import typeBusinessSubBusiness from "@/components/type-business-subBusiness";
import { mapState } from "vuex";
import log from "@/views/activity/activity_speaker_ppt/log.vue";
export default {
    mixins: [websocket],
    components: {
        upImg,
        Member: () => import("../components/member.vue"),
        Slink: () => import("../components/seeTheLink.vue"),
        "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
        Remark: remark,
        orderNum,
        pass,
        Remarks,
        typeBusinessSubBusiness,
    },
    data() {
        var checkEmail = (rule, value, cb) => {
            // 这是验证邮箱的正则表达式
            const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (regemail.test(value)) {
                return cb();
            }
            cb(new Error("请输入合格邮箱"));
        };
        var checkMoblle = (rule, value, cb) => {
            const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
            if (regphone.test(value)) {
                return cb();
            }
            cb(new Error("请输入合法的手机号"));
        };

        return {
          vipDialog:false,
            showViewer: false,
            showurl: "",
            options: [],
            province: "",
            btn: true,
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            keyWord: "",
            pai: [],
            type: "",
            keyword: "",
            showCircles: "",
            bindEnterprise: "",
            jobLevel: "",
            business: "",
            certType: "",
            ugId: "",
            completeInfo: "",
            regDate: [],
            certDate: [],
            querifo: {
                query: "",
                pagenum: 1,
                pagesize: 10,
            },
            order: "",
            userlist: [],
            p_a: false,
            p_a_f: {},
            total: 0,
            addDialogVisible: false,
            addform: {
                username: "",
                password: "",
                email: "",
                mobile: "",
            },
            addFormRulue: {
                nickname: [
                    {
                        required: true,
                        message: "请输入昵称",
                        trigger: "blur",
                    },
                    // {
                    //   min: 3,
                    //   max: 10,
                    //   message: '用户的长度在3~10个字符直接',
                    //   trigger: 'blur'
                    // }
                ],
                enterpriseId: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                ],

                realName: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入电话",
                        trigger: "blur",
                    },
                ],
                weixin: [
                    {
                        required: true,
                        message: "请输入微信",
                        trigger: "blur",
                    },
                ],
                businessInfo: [
                    {
                        required: true,
                        message: "请输入业务介绍",
                        trigger: "blur",
                    },
                ],
                // company: [w
                //   { required: true, message: '请输入公司名称', trigger: 'blur' }
                // ],
                job: [
                    {
                        required: true,
                        message: "请输入职位",
                        trigger: "blur",
                    },
                ],
                // province: [{ required: true, message: '请输入城市', trigger: 'blur' }],
                contactType: [
                    {
                        required: true,
                        message: "请输入联系方式",
                        trigger: "blur",
                    },
                ],
                // type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
                // business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 15,
                        message: "用户的长度在6~15个字符直接",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "请输入邮箱",
                        trigger: "blur",
                    },
                    {
                        validator: checkEmail,
                        trigger: "blur",
                    },
                ],
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        validator: checkMoblle,
                        trigger: "blur",
                    },
                ],
                serch_en_list: [],
                realname: [
                    {
                        required: true,
                        message: "请输入昵称",
                        trigger: "blur",
                    },
                ],
            },
            editdialog: false,
            editform: {
                realName: "",
                nickname: "",
                sex: "",
                qq: "",
                email: "",
                phone: "",
                remark: "",
                avatar: "",
                backgroundImage: "",
                businessInfo: "",
                job: "",
                jobLevel: "",
                isShowCircles: "",
                contactType: 2,
                showContacts: [],
                company: "",
                province: "",
                city: "",
                type: [],
                waId: [],
                weixin: "",
                business: [],
                ugId: 3,
                enterpriseId: "",
                otherContact: "",
              crowd:""
            },
            cityList: [],
            setRoledingvisable: false,
            userinfo: "",
            // 所有角色数据列表
            roleslist: [],
            slectroleName: "",
            Config: null,
            status: "",
            service: "",
            paiform: {},
            header: {
                Token: sessionStorage.getItem("token"),
            },
            action: {
                action: "user",
            },
            addEnData: {
                keyword: "",
                page: 1,
                pageSize: 20,
            },
            city: "",
            loading: false,
            provinceId: "",
            cityId: "",
            serch_en: "",
            serch_en_list: [],
            orderMax: 0,
            regType: "1",
          vipOption:[],
          form_4:{
            vcId:1
          },
          isDerive:false
        };
    },

    computed: {
        citys() {
            return async function(val) {
                console.log(val);

                const { data: res } = await this.$http.get("/admin/Area/getList?code=" + val);
                console.log(res);
                return res.data;
            };
        },
        cQuery() {
            var userId = this.$route.query.userId;
            return {
                page: this.querifo.pagenum,
                pageSize: this.querifo.pagesize,
                keyword: this.keyword,
                order: this.order,
                type: this.querifo.type,
                status: this.status,
                waId: this.service,
                showCircles: this.showCircles,
                certType: this.certType,
                business: this.querifo.business,
                subBusiness: this.querifo.subBusiness,
                completeInfo: this.completeInfo,
                ugId: this.ugId,
                jobLevel: this.jobLevel,
                regDate: this.regDate ? this.regDate.join("~") : "",
                certDate: this.certDate ? this.certDate.join("~") : "",
                province: this.provinceId,
                city: this.cityId,
                bindEnterprise: this.bindEnterprise,
                userId,
            };
        },
        ...mapState({
            userconfig: "config",
        }),
    },
    created() {
      let data = JSON.parse(sessionStorage.getItem('menu'))
      if(data.find(item=>item.name == 'user_manage').children.find(item=>item.name == 'inside').children.find(item=>item.name == 'export_user_data')){
        this.isDerive=true

      }
        // this.getuserlist()
        this.getear();
        this.websocketInit("/wss:2345");
        this.getVipCardType()
        // console.log(this.$store.state.imgUrl)
    },

    watch: {
        provinceId(val) {
            this.findCity(val);
        },
        "$route.query": {
            handler() {
                this.getuserlist();
            },
            immediate: true,
        },
    },
    methods: {
      handleDerived(){
        const data = {
          form: {
            keyword: this.keyword,
            order: this.order,
            type: this.querifo.type,
            status: this.status,
            waId: this.service,
            showCircles: this.showCircles,
            certType: this.certType,
            business: this.querifo.business,
            subBusiness: this.querifo.subBusiness,
            completeInfo: this.completeInfo,
            jobLevel: this.jobLevel,
            createTime: this.regDate ,
            certDate: this.certDate ,
            province: this.provinceId,
            city: this.cityId,
            bindEnterprise: this.bindEnterprise,
          },
          type:1,
        }
        this.$router.push(
            {
              path:'/export_user_data',
              query:{
                data:JSON.stringify(data.form),
                type:data.type
              }
            }
        )

      },
      async getVipCardType(){
        const { data: res } = await this.$http.post("/admin/UserCard/getList?name=&page=1&pageSize=10");
        this.vipOption=res.data.list
        console.log(res)
      },
      async submitVip(){
        const { data: res } = await this.$http.post("/admin/User/sendVip",{...this.form_4});
        if(res.errorCode==200){
          this.getuserlist()
          this.vipDialog=false
        }
      },
      sendVip(row){
        this.form_4.id=row.id
        this.vipDialog=true
      },
        seeTheLink(val) {
            this.$refs.link.getTheDetails(val);
        },
        async synchronousInterior(done) {
            try {
                this.websocket.send(JSON.stringify({ type: "userSync" }));
            } catch (error) {
                this.$message.error("接口没有正常连接");
                done();
            }
        },
        onWebsocketMessage() {
            this.$refs.loadButton.done();
            this.getuserlist();
        },
        show_qy(row) {
            this.p_a = true;
            this.p_a_f = row;
        },
        async editTop(row, val) {
            const { data: res } = await this.$http.post("/admin/User/editTop", { id: row.id, top: val });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getuserlist();
            }
        },
        add_user() {
            //   this.$options.data().editform
            // console.log(this.$data);

            this.editform = this.$options.data.call(this).editform;

            this.addDialogVisible = true;
        },
        async cancelCert(row) {
            const { data: res } = await this.$http.post("/admin/User/cancelCert", { id: row });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getuserlist();
            }
        },
        remoteMethod(qu) {
            if (qu != "") {
                this.loading = true;
                this.$http
                    .get("/admin/enterprise/getEnterpriseByName", {
                        params: {
                            ...this.addEnData,
                            keyword: qu,
                        },
                    })
                    .then(({ data: res }) => {
                        this.loading = false;
                        if (res.errorCode == 200) {
                            console.log(res.data);
                            this.options = [];
                            this.serch_en_list = res.data;
                            res.data.forEach((item) => {
                                this.options.push({
                                    value: item.id,
                                    label: item.company,
                                });
                            });
                            // this.$forceUpdate()
                        }
                    });
            }
        },
        show_one(val) {
            var arr = this.serch_en_list.find((item) => item.id == val);
            this.editform.company = arr.company;
        },
        certification(val) {
            if (!val) {
                this.$message.error("当前无凭证");
                return;
            }
            this.showViewer = true;
            this.showurl = this.baseurl + this.yu + val;
        },
        setImg(val) {
            this.editform.backgroundImage = val;
        },
        op() {
            this.getc();
            this.editform.city = "";
        },
      /**
       * 下拉框
       * @param row
       * @returns {Promise<void>}
       */
      visibleChange(e){
if(e){
  return
}else {
  const  post=async (type)=>{
    const { data: res } = await this.$http.post("/admin/User/editInfo", {...this.paiform,
      sync: type
    });
    if (res.errorCode == 200) {
      this.$message.success(res.message);
      //  row.pai=false
      //  this.tableData=[...this.tableData]
      this.getuserlist();
    }
  }

  this.$confirm(' 是否将小助手同步内部用户', '提示', {
    confirmButtonText: '是',
    cancelButtonText: '否',
    type: 'warning'
  }).then(() => {
    post(1)


  }).catch(() => {
    post(0)


  });
}
      },
      handleRemove(){
        this.visibleChange()
      },

        async handleInputBlur(row) {
            this.paiform.id = row.id;
            const i=this.paiform.waId
            this.paiform.remark = row.remark;
            //   console.log(this.paiform.waId);
            this.paiform.waId = row.waId.toString();
        },
        rowClass() {
            return "background:#F5F7FA;";
        },
        getc() {
            this.$http.get("/admin/Area/getList?code=" + this.editform.province).then(({ data: res }) => {
                if (res.errorCode == 200) {
                    console.log(this.editform.province);
                    console.log(res);
                    this.city = res.data;
                }
            });
        },
        async findCity(val) {
            if (!val) {
                return;
            }
            let { data: res } = await this.$http.get("/admin/Area/getList?code=" + val);
            // console.log(res.data);
            this.cityList = res.data;
            this.cityId = "";
            // console.log(this.cityList)
        },
        sortChange(c) {
            console.log(c);
            if (c.order) {
                var s = "user_" + c.prop;
                if (c.prop == "orderNum") {
                    s = "user_order_num";
                } else {
                    s = "user_" + c.prop;
                }
                var p = c.order.slice(0, c.order.length - 6);
                this.order = s + " " + p;
            } else {
                this.order = "";
            }

            this.getuserlist();
        },
        subimt() {
            this.querifo.pagenum = 1;
            this.getuserlist();
        },

        async getuserlist() {
            const { data: res } = await this.$http.get("/admin/User/getList", {
                params: this.cQuery,
            });
            this.orderMax = res.data.orderMax;
            if (!this.Config) {
                this.Config = this.$store.state.config;
            }
            if (res.errorCode != 200) {
                return;
            }
            this.userlist = res.data.list;
            this.userlist.forEach((item) => {
                if (item.waId) {
                    item.waId = item.waId.split(",");
                }
            });
            this.total = res.data.totalCount;
            // console.log(res)
        },
        handleSizeChange(e) {
            // console.log(e)
            this.querifo.pagesize = e;
            this.getuserlist();
        },
        handleCurrentChange(e) {
            // console.log(e)
            this.querifo.pagenum = e;
            this.getuserlist();
        },
        async userstatechange(userinfo) {
            // 监听switch开关状态改变
            // console.log(userinfo)
            const { data: res } = await this.$http.put(`users/${userinfo.id}/state/${userinfo.mg_state}`);
            // console.log(res)
            if (res.meta.status != 200) {
                userinfo.mg_state = !userinfo.mg_state;
                return this.$message.error("更新用户失败");
            }
            this.$message.success("更新用户成功");
        },
        addColse() {
            this.$refs.addFormRef.resetFields();
        },
        editclose() {
            this.$refs.editFormRef.resetFields();
        },
        addUser() {
            console.log(this.editform);
            this.$refs.addFormRef.validate(async (val) => {
                // console.log(val)
                if (!val) return;
                const { data: res } = await this.$http.post("/admin/user/add", {
                    ...this.editform,
                    business: this.editform.business.toString(),
                    showContacts: this.editform.showContacts.toString(),
                    type: this.editform.type.toString(),
                    waId: this.editform.waId.toString(),
                });
                if (res.errorCode != 200) {
                    return;
                }
                this.$message.success("添加用户成功");
                this.addDialogVisible = false;
                this.getuserlist();
            });
        },
        reset() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.getear();
            this.getuserlist();
        },
        async showEdit(id) {
            // console.log(id)
            this.editform.id = id;
            const { data: res } = await this.$http.get("/admin/User/getById?id=" + id);
            if (res.errorCode != 200) {
                return this.$message.error("查询用户失败");
            }
            this.$refs.member.editform = {
                ...res.data,
                waId: res.data.waId && res.data.waId.toString().split(","),
                showContacts: (res.data.showContacts && res.data.showContacts.toString().split(",")) || [],
                jobLevel: res.data.jobLevel || "",
                enterpriseId: res.data.enterpriseId || "",
            };
            if (res.data.enterpriseId) {
                this.$refs.member.options = [{ value: res.data.enterpriseId, label: res.data.company }];
            }

            this.$refs.member.addDialogVisible = true;
            //   this.editform = {
            //     ...res.data,
            //     waId: res.data.waId && res.data.waId.toString().split(','),
            //     showContacts: res.data.showContacts && res.data.showContacts.toString().split(',') || [],
            //     jobLevel: res.data.jobLevel || '',
            //     enterpriseId: res.data.enterpriseId || ''
            //   }
            //   // this.serch_en=this.editform.company
            //   // console.log(this.editform.business);

            //   this.editform.business = this.editform.business && this.editform.business.split(',') || ''
            //   this.editform.type = this.editform.type && this.editform.type.split(',') || ''
            //   // console.log(this.editform.business)
            //   if (this.editform.enterpriseId) {
            //     this.options = [{
            //       value: this.editform.enterpriseId,
            //       label: this.editform.company
            //     }]

            //   }

            //   if (this.editform.province) {
            //     this.getc()
            //   }
            //   this.editdialog = true
        },
        clear() {
            this.editform.business = [];
        },
        async solutionEnterprise(row) {
            try {
                await this.$confirm("是否解绑企业?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                const { data: res } = await this.$http.post("/admin/User/clearManager", { id: row.id });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    this.getuserlist();
                }
            } catch (error) {}
        },
        edituserinfo() {
            const form = {
                ...this.editform,
                business: this.editform.business.toString(),
                waId: this.editform.waId.toString(),
                showContacts: this.editform.showContacts.toString(),
                type: this.editform.type.toString(),
            };
            // console.log(1)
            if (this.btn) {
                this.$refs.editFormRef.validate(async (val) => {
                    if (!val) return;
                    const { data: res } = await this.$http.post("/admin/User/edit", form);
                    if (res.errorCode == 200) {
                        this.$message.success("更新成功");
                        console.log(this.editform);
                        this.editdialog = false;
                        this.getuserlist();
                    } else {
                    }
                });
            }
            // this.btn = false
        },

        async removeuserid(id) {
            // console.log(id)
            await this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http
                        .delete("/admin/User/del", {
                            params: {
                                id: id,
                            },
                        })
                        .then(({ data: res }) => {
                            if (res.errorCode == 200) {
                                this.$message.success(res.message);
                                if (this.total % 10 == 1) {
                                    this.querifo.pagenum--;
                                }
                                this.getuserlist();
                            } else {
                            }
                        })
                        .catch(() => {
                            return this.$message.error("删除用户失败");
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 这是展示分配角色的对话框
        setRole(id, info) {
            this.$http
                .post("/admin/User/updateStatus", {
                    id: id,
                    status: info,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.getuserlist();
                    }
                });
        },
      setRisk(id,info){
        this.$confirm("是否改变用户的风险状态" , '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$http
              .post("/admin/User/updateStatus", {
                id: id,
                riskStatus: info,
              })
              .then(({ data: res }) => {
                if (res.errorCode == 200) {
                  this.$message.success(res.message);
                  this.getuserlist();
                }
              });
        }).catch(() => {

        });

      },

        saveinRole() {
            if (!this.slectroleName) {
                return this.$message.error("请选择你要分配的角色");
            }
            this.$http
                .put(`users/${this.userinfo.id}/role`, {
                    rid: this.slectroleName,
                })
                .then(() => {
                    this.$message.success("更新角色成功");
                    this.getuserlist();
                    this.setRoledingvisable = false;
                })
                .catch(() => {
                    this.$message.error("更新角色失败!");
                });
        },
        setRoleclosed() {
            this.slectroleName = "";
        },
        handleAvatarSuccess(res) {
            this.editform.avatar = res.data.url;
        },
        getear() {
            this.$http.get("/admin/Area/getList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    this.province = res.data;
                    // console.log(this.province,'//////');
                    // console.log(this.editform.province)
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    display: inline-block;
    margin-left: 15px;
}

.skyblue {
    color: skyblue;
}

.green {
    color: green;
}

.yellow {
    color: pink;
}

.red {
    color: red;
}
</style>
